import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_InstallationConditions(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabInstallationConditions',
        Id: 5,
        Name: 'installation-conditions-tab',
        Image: 'tab-installation-conditions',
        IconImage: getSpriteAsIconStyle('sprite-tab-installation-conditions'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_InstallationConditions_TabInstallationConditions()
        ]
    };
}

function createSPMenu_InstallationConditions_TabInstallationConditions(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions',
        Id: 50,
        Name: 'installationconditions-section',
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'installationconditions-drillingtype-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingType',
                CodelistName: 'drillingTypes',
                UIPropertyId: 'drillingTypeId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'installationconditions-holeType-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.HoleType',
                CodelistName: 'holeTypes',
                UIPropertyId: 'holeTypeId'
            },
            {
                ControlType: 'Dropdown',
                Name: 'installationconditions-drillingaid-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DrillingAid',
                CodelistName: 'drillingAids',
                UIPropertyId: 'drillingAidId'
            },
            {
                ControlType: 'TextBox',
                Name: 'installationconditions-depthofrecess-input',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabInstallationConditions.RegionInstallationConditions.DepthOfRecess',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'depthOfRecess'
            }
        ]
    };
}
