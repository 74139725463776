import { Injectable } from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import { MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { FavoritesServiceBase, IFavoritesInfo } from '@profis-engineering/pe-ui-common/services/favorites.common';
import { Observable } from 'rxjs/internal/Observable';
import { designTypes } from './design.service';

// TODO FILIP: user-settings is still not modularized
// we have to add code to user-settings when we create a new module
const menu3DStrength = 'Menu3DSPStrength';
const menu3DPunch = 'Menu3DSPPunch';

const favoriteTypes: Record<number, string> = {
    [designTypes.strength.id]: menu3DStrength,
    [designTypes.punch.id]: menu3DPunch
};

export interface FavoritesServiceBaseInternal extends Omit<FavoritesServiceBase, 'info'> {
    info: Record<string, IFavoritesInfo>;
}

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {
    private baseService!: FavoritesServiceBaseInternal;

    public setBaseService(baseService: FavoritesServiceBaseInternal): void {
        this.baseService = baseService;

        this.dataChange.subscribe(data => this.initFavorites(data as IUserMenuFavorites));
    }

    public get dataChange(): Observable<object> {
        return this.baseService.dataChange;
    }

    protected get infoBase(): Record<string, IFavoritesInfo> {
        return this.baseService.info;
    }

    public get menu3DRightOrder(): string[] {
        return this.baseService.menu3DRightOrder;
    }

    public getMenuRegionIdFavorites(id: string, designType: number): string | undefined {
        // TODO FILIP: fix pe-ui to only call this for sp module
        if (designType in favoriteTypes) {
            return id;
        }

        return undefined;
    }

    public getFavoritesType(menuType: MenuType, designType: number): string |undefined {
        // TODO FILIP: fix pe-ui to only call this for sp module
        if (menuType == MenuType.Menu3D) {
            return favoriteTypes[designType];
        }

        return undefined;
    }

    public hasFavorites(designType: number) : boolean {
        return this.getInfo(MenuType.Menu3D, designType).favorites.length > 0;
    }

    private initFavorites(data: IUserMenuFavorites): void {
        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, designTypes.strength.id),
            data?.favorites?.[menu3DStrength]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, designTypes.punch.id),
            data?.favorites?.[menu3DPunch]
        );
    }

    private initFavoritesInfo(favInfo: IFavoritesInfo, favData?: string[]): void {
        favInfo.dbFavorites = favData ?? [];
        favInfo.favorites = favData ?? [];
    }

    private getInfo(menuType: MenuType, designType: number): IFavoritesInfo {
        const favoritesType = this.getFavoritesType(menuType, designType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.infoBase[favoritesType] == null) {
            this.infoBase[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.infoBase[favoritesType];
    }
}
