import { Component, Input, OnInit } from '@angular/core';
import { ApprovalsService } from '../../services/approval.service';
import { DataService } from '../../services/data.service';
import { DesignService, PunchDesignDetails } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

interface IInputElement {
    value: string;
    label: string;
}

const noImageAvailable = 'sprite-product-dialog-no-photo-available';

@Component({
    selector: 'app-export-report-summary-punch',
    templateUrl: './export-report-summary-punch.component.html',
    styleUrls: ['./export-report-summary-punch.component.scss']
})
export class ExportReportSummaryPunchComponent implements OnInit {
    @Input()
    public collapsed = false;

    @Input()
    public designDetails!: PunchDesignDetails;

    @Input()
    public modelImage = '';

    public baseMaterial!: IInputElement;
    public strengthElementType!: IInputElement;
    public strengthElementSize!: IInputElement;
    public approval!: IInputElement;
    public proof!: string;
    public drillLength!: string;
    public strengtheningElementImage!: string;

    constructor(
        public localizationService: LocalizationService,
        public approvalsService: ApprovalsService,
        public unitService: UnitService,
        public dataService: DataService,
        public designService: DesignService
    ) {}

    public ngOnInit(): void {
        this.initSummarySection();
    }

    private initSummarySection() {
        //TODO determine actual fields and add actual values (from nola DB) once available
        this.strengthElementType = {
            value: this.getFastenerFamilyRegionalName(this.designDetails.properties.fastenerFamilyId, this.designDetails.regionId),
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementType')
        };
        this.strengthElementSize = {
            value: this.dataService.fastenersById[this.designDetails.properties.fastenerId].name,
            label: this.localizationService.getString('SP.ExportReport.Summary.StrengtheningElementSize')
        };

        this.baseMaterial = {
            value: this.dataService.getItemName(this.dataService.baseMaterialsById[this.designDetails.properties.baseMaterialId]),
            label: this.localizationService.getString('SP.ExportReport.Summary.BaseMaterial')
        };

        this.approval = {
            value: this.approvalsService.getApprovalNumber(this.designDetails.properties.approval),
            label: this.localizationService.getString('SP.Approval.Title')
        };

        this.proof = this.getDesignMethod();

        this.drillLength = '-';

        this.setStrengtheningElementPhoto(this.designDetails.properties.fastenerFamilyGroupId);
    }
    private getDesignMethod(): string {
        const designMethodName = this.designDetails.properties.designMethodName;
        const approvalName = this.localizationService.getString(this.dataService.postInstalledReinforcementDesignsById[this.designDetails.properties.postInstalledReinforcementDesignId].nameKey);

        return (this.designService.isCalculationValid(this.designDetails.calculateResult) && designMethodName && approvalName)
            ? `${designMethodName} + ${approvalName}`
            : `${this.localizationService.getString('SP.DesignMethod.None')}`;
    }

    private getFastenerFamilyRegionalName(fastenerFamilyId: number, regionId: number): string {
        const fastenerFamily = this.dataService.fastenerFamiliesById[fastenerFamilyId];

        return fastenerFamily.regionalNames?.[regionId] ?? fastenerFamily.name;
    }

    private setStrengtheningElementPhoto(fastenerFamilyGroupId: number) {
        const fastenerFamilyGroup = this.dataService.fastenerFamilyGroupsById[fastenerFamilyGroupId];

        if (fastenerFamilyGroup == null || !fastenerFamilyGroup.image) {
            this.strengtheningElementImage = noImageAvailable;
        }
        else {
            this.strengtheningElementImage = 'sprite-product-dialog-' + fastenerFamilyGroup.image;
        }
    }
}
