import { StrengthModel } from '../../../strength-gl-model';
import { StrengthMeasurementPlaneRowData } from './strength-measurements';

export function SlabLengthMeasurement(model: StrengthModel) : StrengthMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: -model.baseMaterial.length / 2,
                x2: model.baseMaterial.length / 2,
                y1: model.baseMaterial.width / 2,
                y2: model.baseMaterial.width / 2,
                property: 'slabLength',
                custom: false
            }
        ]
    };
}

export function SlabHeightMeasurement(model: StrengthModel) : StrengthMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: -model.baseMaterial.height / 2,
                x2: model.baseMaterial.height / 2,
                y1: model.baseMaterial.length / 2,
                y2: model.baseMaterial.length / 2,
                property: 'slabHeight',
                custom: false
            }
        ]
    };
}

export function SlabWidthMeasurement(model: StrengthModel) : StrengthMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: -model.baseMaterial.width / 2,
                x2: model.baseMaterial.width / 2,
                y1: model.baseMaterial.length / 2,
                y2: model.baseMaterial.length / 2,
                property: 'slabWidth',
                custom: false
            }
        ]
    };
}
