import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';

export function createSPMenu_BaseMaterial(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial',
        Id: 2,
        Name: 'base-material-tab',
        Image: 'tab-base-material',
        IconImage: getSpriteAsIconStyle('sprite-tab-base-material'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_BaseMaterial_BaseMaterial(),
            createSPMenu_BaseMaterial_Temperature(),
            createSPMenu_BaseMaterial_Geometry(),
            createSPMenu_BaseMaterial_Openings(),
            createSPMenu_BaseMaterial_Openings_Opening1(),
            createSPMenu_BaseMaterial_Openings_Opening2(),
            createSPMenu_BaseMaterial_Openings_Opening3(),
        ]
    };
}

function createSPMenu_BaseMaterial_BaseMaterial(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial',
        Id: 20,
        Name: 'basematerial-section',
        Controls: [
            {
                CodelistName: 'baseMaterial',
                ControlType: 'Dropdown',
                Name: 'basematerial-basematerial-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.BaseMaterial',
                UIPropertyId: 'baseMaterialId'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccyl',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCyl',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'fcCylinder'
            },
            {
                ControlType: 'TextBox',
                Name: 'basematerial-fccube',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionBaseMaterial.FcCube',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'fcCube'
            },
        ]
    };
}


function createSPMenu_BaseMaterial_Temperature(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature',
        Id: 21,
        Name: 'temperature-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'temperature-shortterm',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm',
                UnitGroup: UnitGroup.Temperature,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.ShortTerm',
                UIPropertyId: 'shortTermTemperature'
            },
            {
                ControlType: 'TextBox',
                Name: 'temperature-longterm',
                Size: 50,
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm',
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionTemperature.LongTerm',
                UnitGroup: UnitGroup.Temperature,
                UIPropertyId: 'longTermTemperature'
            },
        ]
    };
}

export function createSPMenu_BaseMaterial_Geometry(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry',
        Name: 'geometry-section',
        Id: 22,
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'span-neg-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegX',
                UIPropertyId: 'spanNegX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'span-neg-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanNegY',
                UIPropertyId: 'spanNegY'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'span-pos-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosX',
                UIPropertyId: 'spanPosX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'span-pos-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.SpanPosY',
                UIPropertyId: 'spanPosY'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'thickness',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Thickness',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionGeometry.Thickness',
                UIPropertyId: 'thickness'
            },
        ]
    };
}

function createSPMenu_BaseMaterial_Openings(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings',
        Name: 'openings-section',
        Id: 23,
        Controls: [
            {
                ControlType: 'Dropdown',
                Name: 'numberofopenings-dropdown',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.NumberOfOpenings',
                CodelistName: 'openingsNumber',
                UIPropertyId: 'openingsNumberId'
            },
        ]
    };
}

function createSPMenu_BaseMaterial_Openings_Opening1(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpening1',
        Id: 24,
        Name: 'openings-opening1-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening1-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                UIPropertyId: 'punchOpening1Length'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening1-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                UIPropertyId: 'punchOpening1Width'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening1-origin-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                UIPropertyId: 'punchOpening1OriginX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening1-origin-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                UIPropertyId: 'punchOpening1OriginY'
            }
        ]
    };
}

function createSPMenu_BaseMaterial_Openings_Opening2(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpening2',
        Id: 25,
        Name: 'openings-opening2-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening2-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                UIPropertyId: 'punchOpening2Length'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening2-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                UIPropertyId: 'punchOpening2Width'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening2-origin-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                UIPropertyId: 'punchOpening2OriginX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening2-origin-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                UIPropertyId: 'punchOpening2OriginY'
            }
        ]
    };
}

function createSPMenu_BaseMaterial_Openings_Opening3(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpening3',
        Id: 32,
        Name: 'openings-opening3-section',
        Controls: [
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening3-length',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningLength',
                UIPropertyId: 'punchOpening3Length'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening3-width',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningWidth',
                UIPropertyId: 'punchOpening3Width'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening3-origin-x',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginX',
                UIPropertyId: 'punchOpening3OriginX'
            },
            {
                ControlType: 'TextBox',
                UnitGroup: 100,
                Name: 'opening3-origin-y',
                Size: 50,
                DisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                TitleDisplayKey: 'SP.Navigation.TabBaseMaterial.RegionOpenings.OpeningOriginY',
                UIPropertyId: 'punchOpening3OriginY'
            }
        ]
    };
}
