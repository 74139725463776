<ng-container *ngIf="changedProperties != null">
    <ul *ngFor="let item of changedProperties">
      <li>
        <span [innerHTML]="item.name"></span>
        <ul>
            <app-design-verification-changes-loop *ngIf="item.children != null && item.children.length > 0" [changedProperties]="item.children"></app-design-verification-changes-loop>
        </ul>
    </li>
    </ul>
</ng-container>

