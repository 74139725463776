import { StrengthMenu } from './static-menu.strength.definitions';
import { createSPMenu_Application } from './static-menu.strength.tabs.application';
import { createSPMenu_BaseMaterial } from './static-menu.strength.tabs.base-material';
import { createSPMenu_ExistingReinforcement } from './static-menu.strength.tabs.existing-reinforcements';
import { createSPMenu_InstallationConditions } from './static-menu.strength.tabs.installation-conditions';
import { createSPMenu_Loads } from './static-menu.strength.tabs.loads';
import { createSPMenu_PostInstalledElement } from './static-menu.strength.tabs.post-installed-element';

export function strengthCreateMenu(): StrengthMenu {
    return {
        Id: 1,
        Favorites: true,
        Tabs: [
            createSPMenu_Application(),
            createSPMenu_BaseMaterial(),
            createSPMenu_ExistingReinforcement(),
            createSPMenu_InstallationConditions(),
            createSPMenu_PostInstalledElement(),
            createSPMenu_Loads()
        ],
        Footer: {
            Controls: []
        }
    };
}
