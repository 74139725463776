<pe-modal-header [modalInstance]="modalInstance" [title]="getTitle()"></pe-modal-header>

<pe-modal-body>
    <div>
        <span l10n="SP.DesignValidation.WarningDescription"></span>
        <div class="properties-list">
            <app-design-verification-changes-loop [changedProperties]="modalInstance.input?.changedProperties"></app-design-verification-changes-loop>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Cta'" (buttonClick)="close()" [text]="translate('SP.General.OK')">
    </pe-button>
</pe-modal-footer>
