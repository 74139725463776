import { PickKeys } from 'ts-essentials';

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';
import { UserDetails } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Tracking';
import { trySendingUsingFetch } from '@profis-engineering/pe-ui-common/helpers/browser';
import {
    getJSONDateWithTimezoneOffset
} from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';

import { environment } from '../../environments/environment';
import { substringAfterLast } from '../helpers/string-helper';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { DataService } from './data.service';
import {
    AllPropertyIds, CalculationStatus, DesignDetails, DesignTypeId, DesignTypePunchId, designTypes,
    DesignTypeStrengthId, designTypeSwitch, LoadCombination, Properties, PropertyId, PropertyInfo,
    PropertyInfoType, PunchDesignDetails, punchPropertyInfos, StrengthCalculationResult,
    StrengthDesignDetails, StrengthProperties, strengthPropertyInfos, UtilizationItem,
    UtilizationType
} from './design.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

interface TrackingData {
    designTypeId: DesignTypeId;
    designId: string | undefined;
    templateId: string | undefined;
    activities: TrackingActivities;
}

interface StrengthTrackingData extends TrackingData {
    designTypeId: DesignTypeStrengthId;
}

interface PunchTrackingData extends TrackingData {
    designTypeId: DesignTypePunchId;
}

interface DataModel {
    name?: string;
    nameKey?: string;
}

type TrackingActivities = Record<string, string | undefined>;

export type OpenType =
    /** new design (normal or trimble connect) */
    'Blank' |
    /** open design */
    'OpenExisting' |
    /** import design */
    'ImportedProfis3File' |
    /** new design from template */
    'BlankFromTemplate' |
    /** new or open template */
    'TemplateEdit';

export interface TrackingDetails {
    openTime: number;
    openType: OpenType;
    counters: TrackingCounters;
}

export interface TrackingCounters {
    approvalViewed: number;
    reportCreated: number;
    reportCreatedWithDefaultTemplate: number;
    reportCreatedWithUserTemplate: number;
    reportCreatedWithCustomTemplate: number;
    designUndo: number;
    designRedo: number;
    designExport: number;
    designDuplicate: number;
    designImport: number;
    headerMenuOpened: number;
    headerOnlineTechnicalInformation: number;
    specificationTextExport: number;
}

export type TrackingCounterId = keyof TrackingCounters;

const counterNames: Readonly<Record<TrackingCounterId, string>> = {
    approvalViewed: 'Approval',
    reportCreated: 'ReportCreated',
    reportCreatedWithDefaultTemplate: 'ReportCreatedWithDETemplate',
    reportCreatedWithUserTemplate: 'ReportCreatedWithUSTemplate',
    reportCreatedWithCustomTemplate: 'ReportCreatedWithCUTemplate',
    designUndo: 'Undo',
    designRedo: 'Redo',
    designExport: 'DesignExportedToDevice',
    designDuplicate: 'DuplicateDesign',
    designImport: 'ImportExistingDesign',
    headerMenuOpened: 'MenuOpened',
    headerOnlineTechnicalInformation: 'OnlineTechnicalInformation',
    specificationTextExport: 'SpecificationTextExport'
};

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private localizationService: LocalizationService,
        private unitService: UnitService,
        private dataService: DataService,
        private numberService: NumberService,
        private userService: UserService
    ) { }

    public async trackOnTemplateOpen(designDetails: DesignDetails, openType: OpenType): Promise<TrackingDetails> {
        const trackingDetails = this.createTrackingDetails(openType);

        await this.sendTracking('TrackOnProjectTemplateOpen', designDetails, trackingDetails, false);
        await this.trackOnTemplateChange(designDetails, trackingDetails);

        return trackingDetails;
    }

    public async trackOnTemplateClose(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        await this.sendTracking('TrackOnProjectTemplateClose', designDetails, trackingDetails, true);
    }

    public async trackOnDesignOpen(designDetails: DesignDetails, openType: OpenType): Promise<TrackingDetails> {
        const trackingDetails = this.createTrackingDetails(openType);

        await this.sendTracking('TrackOnProjectDesignOpen', designDetails, trackingDetails, false);
        await this.trackOnDesignChange(designDetails, trackingDetails);

        return trackingDetails;
    }

    public async trackOnDesignClose(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        await this.sendTracking('TrackOnProjectDesignClose', designDetails, trackingDetails, true);
    }

    public async trackOnDesignChange(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        await this.sendTracking('TrackOnProjectDesignChange', designDetails, trackingDetails, true);
    }

    public async trackOnTemplateChange(designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        await this.sendTracking('TrackOnProjectTemplateChange', designDetails, trackingDetails, true);
    }

    public async trackOnCloseBrowserUnloadEvent(designDetails: DesignDetails, trackingDetails: TrackingDetails, isTemplate: boolean): Promise<void> {
        const url = isTemplate ? 'TrackOnProjectTemplateCloseSync' : 'TrackOnProjectDesignCloseSync';
        await this.sendTrackingOnBrowserUnloadEvent(url, designDetails, trackingDetails);
    }

    public createTrackingDetails(openType: OpenType): TrackingDetails {
        return {
            openTime: Date.now(),
            openType,
            counters: {
                approvalViewed: 0,
                reportCreated: 0,
                reportCreatedWithDefaultTemplate: 0,
                reportCreatedWithUserTemplate: 0,
                reportCreatedWithCustomTemplate: 0,
                designUndo: 0,
                designRedo: 0,
                designExport: 0,
                designDuplicate: 0,
                designImport: 0,
                headerMenuOpened: 0,
                headerOnlineTechnicalInformation: 0,
                specificationTextExport: 0
            }
        };
    }

    public async sendTracking(url: string, designDetails: DesignDetails, trackingDetails: TrackingDetails, includeActivities: boolean): Promise<void> {
        try {
            const absoluteUrl = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/${url}`;
            const data = this.prepareTrackingData(designDetails, trackingDetails, includeActivities);

            if (!data) {
                return;
            }

            const request = new HttpRequest<TrackOnOpenRequest>('POST', absoluteUrl, data, {
                responseType: 'json'
            });

            await this.apiService.request(request, { supressErrorMessage: true });
        }
        catch (error) {
            // tracking should fail silently
            console.error(error);
        }
    }

    public async sendTrackingOnBrowserUnloadEvent(url: string, designDetails: DesignDetails, trackingDetails: TrackingDetails): Promise<void> {
        const absoluteUrl = `${this.sharedEnvironmentService.data?.peTrackingServiceUrl}Tracking/${url}`;
        const data = this.prepareTrackingData(designDetails, trackingDetails, true);

        if (!data) {
            return;
        }

        const httpHeaders = this.userService.getHeaders(absoluteUrl, true);
        const jsonData = JSON.stringify(data);

        // Normally, when a document is unloaded, all associated network requests are aborted.
        // But the keepalive option tells the browser to perform the request in the background, even after it leaves the page.
        // So this option is essential for our request to succeed.
        // https://javascript.info/fetch-api#keepalive
        await trySendingUsingFetch(absoluteUrl, httpHeaders, jsonData);
    }

    private prepareTrackingData(designDetails: DesignDetails, trackingDetails: TrackingDetails, includeActivities: boolean): TrackOnOpenRequest | undefined {
        const trackingData = this.createTrackingData(trackingDetails, designDetails, includeActivities);
        console.debug('Tracking:', trackingData);

        // skip tracking if disabled (keep console log even if tracking is disabled)
        if (!environment.trackingEnabled) {
            return;
        }

        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const data: TrackOnOpenRequest = {
            UseDirectTracking: true,
            DesignType: trackingData.designTypeId,
            TrackingData: {
                userDetails,
                uiVersion: this.sharedEnvironmentService.data?.applicationVersion,
                designTypeName: this.getDesignType(designDetails.designTypeId),
                activities: trackingData.activities
            },
            DesignId: trackingData.designId,
            TemplateId: trackingData.templateId
        };

        return data;
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset,
            IPAddress: srcData.UserIpAddress,
            UserName: srcData.UserName,
            UserId: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType,
            IsTrial: srcData.IsTrial
        };
    }

    private createTrackingData(trackingDetails: TrackingDetails, designDetails: DesignDetails, includeActivities: boolean): TrackingData {
        return designTypeSwitch(designDetails.designTypeId,
            () => this.strengthTrackingData(trackingDetails, designDetails as StrengthDesignDetails, includeActivities),
            () => this.punchTrackingData(trackingDetails, designDetails as PunchDesignDetails, includeActivities),
        );
    }

    private commonTrackingData(trackingDetails: TrackingDetails, designDetails: DesignDetails, includeActivities: boolean): TrackingData {
        const activities: Record<string, string | undefined> = {};

        if (includeActivities) {
            activities['DesignId'] = designDetails.designId?.toString();
            activities['TemplateId'] = designDetails.templateId?.toString();
            activities['ApplicationMode'] = 'online';
            activities['LANGUAGE'] = this.localizationService.selectedLanguage;
            activities['REGION'] = designDetails.commonRegion.displayKey;
            activities['ProjectOpenType'] = trackingDetails.openType;

            const elapsedMs = Date.now() - trackingDetails.openTime;
            const elapsedSeconds = Math.floor((elapsedMs / 1000) % 60);
            const elapsedMinutes = Math.floor((elapsedMs / 60000) % 60);
            const elapsedHours = Math.floor(elapsedMs / 3600000);

            activities['SessionTime'] = `${elapsedHours}h:${elapsedMinutes}m:${elapsedSeconds}s`;

            // counters
            for (const _counterId in trackingDetails.counters) {
                const counterId = _counterId as TrackingCounterId;
                const counterName = counterNames[counterId];
                const counter = trackingDetails.counters[counterId];

                activities[counterName] = counter.toString();
            }
        }

        return {
            designTypeId: designDetails.designTypeId,
            designId: designDetails.designId,
            templateId: designDetails.templateId,
            activities
        };
    }

    private getDesignType(designTypeId: DesignTypeId): string {
        return designTypeSwitch<string>(designTypeId,
            () => 'ShearStrengthening',
            () => 'PunchingShearStrengthening'
        );
    }

    private strengthTrackingData(trackingDetails: TrackingDetails, designDetails: StrengthDesignDetails, includeActivities: boolean): StrengthTrackingData {
        let activities: TrackingActivities = {};

        if (includeActivities) {
            activities = {
                ...this.getTrackingActivitiesForSimpleProperties(designDetails, strengthPropertyInfos),
                ...this.getStrengthTrackingActivitiesCustom(designDetails),
                'DesignMethod': 'No method' // will be overriden if calculation result is present and OK
            };

            if (designDetails.calculateResult != null) {
                activities = {
                    ...activities,
                    ...this.getStrengthTrackingActivitiesCalulationResults(designDetails.calculateResult, designDetails.properties)
                };

                if (designDetails.calculateResult.calculationStatus == CalculationStatus.OK) {
                    activities['DesignMethod'] = this.getFullDesignMethodText(designDetails.properties);
                }
            }
        }

        const commonTrackingData = this.commonTrackingData(trackingDetails, designDetails, includeActivities);
        return {
            ...commonTrackingData,
            designTypeId: designTypes.strength.id,
            activities: {
                ...commonTrackingData.activities,
                ...activities
            }
        };
    }

    private getFullDesignMethodText(properties: StrengthProperties): string | undefined {
        const { designMethodName: designMethodText, postInstalledReinforcementDesignId } = properties;
        const approvalText = this.formatDataValue('postInstalledReinforcementDesignsById', postInstalledReinforcementDesignId);
        return (designMethodText && approvalText) ? `${designMethodText} + ${approvalText}` : 'No method';
    }

    private punchTrackingData(trackingDetails: TrackingDetails, designDetails: PunchDesignDetails, includeActivities: boolean): PunchTrackingData {
        let activities: TrackingActivities = {};

        if (includeActivities) {
            activities = {
                ...this.getTrackingActivitiesForSimpleProperties(designDetails, punchPropertyInfos),
                ...this.getPunchTrackingActivitiesCustom()
            };

            if (designDetails.calculateResult != null) {
                // TODO TEAM: add calculation reesult tracking activities
            }
        }

        const commonTrackingData = this.commonTrackingData(trackingDetails, designDetails, includeActivities);
        return {
            ...commonTrackingData,
            designTypeId: designTypes.punch.id,
            activities: {
                ...commonTrackingData.activities,
                ...activities
            }
        };
    }

    /**
     * This method is used to get tracking activities for all properties that are not custom and have 'trackingName' property set
     * @param designDetails
     * @param propertyInfos
     * @returns
     */
    private getTrackingActivitiesForSimpleProperties(designDetails: DesignDetails, propertyInfos: Record<PropertyId, PropertyInfo>): TrackingActivities {
        const activities: TrackingActivities = {};
        const properties = designDetails.properties;

        for (const key in properties) {
            const propertyId = key as PropertyId;
            const propertyInfo = propertyInfos[propertyId];

            if (propertyInfo == null) {
                console.warn(`PropertyInfo not found for property: ${propertyId}`);
                continue;
            }

            if (designDetails.propertyDetails[propertyId]?.hidden) {
                continue;
            }

            const trackingName = (propertyInfo as { trackingName?: string }).trackingName;
            if (!trackingName) {
                continue;
            }

            const value = this.formatTrackingValue(
                propertyInfo,
                properties[propertyId] ?? designDetails.propertyDetails[propertyId].defaultValue
            );

            const excludeFromTracking = this.excludeSimplePropertyFromTracking(propertyId, properties);
            if (value && !excludeFromTracking) {
                activities[trackingName] = value;
            }
        }

        return activities;
    }

    /**
     * This method contains custom logic that is used to exclude properties from tracking
     *
     * Most of properties should already be automatically excluded by 'propertyDetails.hidden' property or ignoring 'trackingName' property in propertyInfos,
     * but some properties require additional logic (e.g. design settings), since they are technically never hidden
     */
    private excludeSimplePropertyFromTracking(propertyId: AllPropertyIds, properties: Properties): boolean {
        // TODO when implementing Punching tracking, check if adjustments are needed here
        // EN 1992-1-1
        if (properties.designStandardId == 1) {
            return propertyId == 'etaT' || propertyId == 'kc';
        }
        // SIA 262
        if (properties.designStandardId == 2) {
            return propertyId == 'alphaCC';
        }

        return false;
    }

    private formatTrackingValue(propertyInfo: PropertyInfo, value: unknown): string | undefined {
        switch (propertyInfo.type) {
            case PropertyInfoType.CodeList:
                return this.formatDataValue(
                    propertyInfo.dataName as PickKeys<DataService, Record<number, DataModel>>,
                    value as number
                );

            case PropertyInfoType.Unit: {
                let unitType = this.unitService.getInternalUnit(propertyInfo.unitGroup);
                let unitValue = value;

                if (propertyInfo.unitGroup == UnitGroup.Angle) {
                    const convertRadToDegree = unitType == UnitType.rad;
                    unitType = convertRadToDegree ? UnitType.degree : unitType;
                    unitValue = convertRadToDegree
                        ? this.unitService.convertUnitValueToUnit(new UnitValue(value as number, UnitType.rad), UnitType.degree).value
                        : value;
                }

                return this.formatNumberValue(unitValue as number | null, unitType, propertyInfo.precision);
            }

            case PropertyInfoType.Scalar:
                return this.formatNumberValue(value as number | null, undefined, propertyInfo.precision ?? 2);

            case PropertyInfoType.Boolean:
                return value as boolean ? 'True' : 'False';

            case PropertyInfoType.String: {
                let strValue = value as string;
                if (strValue.length > 255) {
                    strValue = strValue.substring(0, 252) + '...';
                }
                return strValue;
            }

            default:
                throw new Error(`Unhandled tracking value of property type: ${propertyInfo.type}]`);
        }
    }

    private formatNumberValue(value: number | null, unitType?: UnitType, precision?: number) {
        if (value == null)
            return undefined;

        // this is done this way because formatUnitValueArgs does not allow for empty string as a separator
        const formattedValue = this.unitService.formatUnitValueArgs(value, unitType, precision, '.', ',', undefined, false);
        return formattedValue.replaceAll(',', '');
    }

    private formatDataValue(dataName: PickKeys<DataService, Record<number, DataModel>>, dataId: number): string | undefined {
        if (dataId == null)
            return undefined;

        const value = (this.dataService[dataName] as Record<number, DataModel>)[dataId];
        const name = value.nameKey ?? value.name;
        const dataValue = name ? substringAfterLast(name, '.') : undefined;
        return dataValue;
    }


    /**
     * Tracking for all custom strength properties, that require special logic
     * @param properties
     * @returns
     */
    private getStrengthTrackingActivitiesCustom(designDetails: StrengthDesignDetails): TrackingActivities {
        const properties = designDetails.properties;
        return {
            'PRODUCT_NAME': this.getProductName(properties.fastenerFamilyId, designDetails.regionId),
            'NR_Openings': properties.defineOpening ? '1' : '0',
            ...this.getLoadsTrackingActivities(properties.loadCombinations),
            ...this.getOpeningTrackingActivities(properties)
        };
    }

    private getOpeningTrackingActivities(properties: StrengthProperties): TrackingActivities {
        if (!properties.defineOpening) {
            return {};
        }
        return {
            'NR_Zones': '8',
            'Zone1SizeX': this.formatTrackingValue(strengthPropertyInfos['zone1Length'], properties.zone1Length),
            'Zone1SizeY': this.formatTrackingValue(strengthPropertyInfos['zone1Width'], properties.zone1Width),
            'Zone2SizeX': this.formatTrackingValue(strengthPropertyInfos['zone2Length'], properties.zone2Length),
            'Zone2SizeY': this.formatTrackingValue(strengthPropertyInfos['zone2Width'], properties.zone2Width),
            'Zone3SizeX': this.formatTrackingValue(strengthPropertyInfos['zone3Length'], properties.zone3Length),
            'Zone3SizeY': this.formatTrackingValue(strengthPropertyInfos['zone3Width'], properties.zone3Width),
            'Zone4SizeX': this.formatTrackingValue(strengthPropertyInfos['zone4Length'], properties.zone4Length),
            'Zone4SizeY': this.formatTrackingValue(strengthPropertyInfos['zone4Width'], properties.zone4Width),
            'Zone5SizeX': this.formatTrackingValue(strengthPropertyInfos['zone5Length'], properties.zone5Length),
            'Zone5SizeY': this.formatTrackingValue(strengthPropertyInfos['zone5Width'], properties.zone5Width),
            'Zone6SizeX': this.formatTrackingValue(strengthPropertyInfos['zone6Length'], properties.zone6Length),
            'Zone6SizeY': this.formatTrackingValue(strengthPropertyInfos['zone6Width'], properties.zone6Width),
            'Zone7SizeX': this.formatTrackingValue(strengthPropertyInfos['zone7Length'], properties.zone7Length),
            'Zone7SizeY': this.formatTrackingValue(strengthPropertyInfos['zone7Width'], properties.zone7Width),
            'Zone8SizeX': this.formatTrackingValue(strengthPropertyInfos['zone8Length'], properties.zone8Length),
            'Zone8SizeY': this.formatTrackingValue(strengthPropertyInfos['zone8Width'], properties.zone8Width),
        };
    }

    private getProductName(fastenerFamilyId: number, regionId: number): string | undefined {
        const fastenerFamily = this.dataService.fastenerFamiliesById[fastenerFamilyId];
        return fastenerFamily?.regionalNames?.[regionId];
    }

    private getLoadsTrackingActivities(loadCombinations: LoadCombination[]): TrackingActivities {
        const activities: TrackingActivities = {};
        if (!loadCombinations) return activities;

        // TODO once we support multiple load combinations, we need to update this
        // currently load combination names are always null (and there's only one),
        // so there's no way to connect active one to implement logic upfront
        const zoneLoads = loadCombinations[0].zoneLoads;
        for (const load of zoneLoads) {
            activities[`Zone${load.zoneNumber}V_Ed`] = this.formatNumberValue(load.load, UnitType.N);
        }

        return activities;
    }

    /**
     * Tracking for all custom punch properties, that require special logic
     * @param properties
     * @returns
     */
    private getPunchTrackingActivitiesCustom(): TrackingActivities {
        return {
            // TODO add custom punch tracking activities here
        };
    }

    /**
     * Get calculation results tracking activities for strength design
     * @param calculateResult
     * @returns
     */
    private getStrengthTrackingActivitiesCalulationResults(calculateResult: StrengthCalculationResult, properties: StrengthProperties): TrackingActivities {
        function extractZoneNumberFromId(zoneId: string): number {
            return parseInt(zoneId.substring(4));
        }

        const utilizationsByZone = calculateResult.utilizationResults.reduce((resultsByZone: Record<number, UtilizationItem[]>, result) => {
            const zoneNumber = extractZoneNumberFromId(result.zoneId);
            if (!resultsByZone[zoneNumber]) {
                resultsByZone[zoneNumber] = [];
            }
            resultsByZone[zoneNumber] = result.utilizationItems;
            return resultsByZone;
        }, {});


        return {
            'DesignSatisfied': calculateResult?.isDesignValid ? 'True' : 'False',
            ...this.getZoneUtilizationsTrackingActivities(utilizationsByZone, properties)
        };
    }

    private getZoneUtilizationsTrackingActivities(utilizationsByZone: Record<number, UtilizationItem[]>, properties: StrengthProperties): TrackingActivities {
        const activities: TrackingActivities = {};

        let maxEmbedmentDepth: number | null = null;
        const maxUtilizations: number[] = [];

        for (const zoneNumberKey in utilizationsByZone) {
            const zoneNumber = parseInt(zoneNumberKey);
            const zoneUtilizations = utilizationsByZone[zoneNumber];
            const zoneStrengthenedPropertyKey = `zone${zoneNumberKey}StrengtheningElementDefinition` as keyof StrengthProperties;
            const zoneStrengthened = properties[zoneStrengthenedPropertyKey] as boolean;

            maxUtilizations.push(this.calculateMaxUtilization(zoneUtilizations, zoneStrengthened));

            for (const utilization of zoneUtilizations) {
                switch (utilization.utilizationType) {
                    // custom handling for some utilizations
                    case UtilizationType.StrengthenedMemberNumberOfStrengtheningElements:
                        activities[`Zone${zoneNumber}NoOfElements`] = utilization.actualValue?.toString();
                        break;
                    case UtilizationType.StrengthenedMemberDrillLength:
                        maxEmbedmentDepth = this.keepMaxValue(maxEmbedmentDepth, utilization.actualValue);
                        break;
                    // tracking for tensile force utilization
                    case UtilizationType.AdditionalTensileForceFromShear:
                        this.trackUtilizationNumberValue(utilization, zoneNumber, activities);
                        break;
                    // default tracking for all mapped percentage utilizations
                    default:
                        this.trackUtilizationValueAndReturnMax(utilization, zoneNumber, activities);
                        break;
                }
            }
        }

        activities['EMBEDMENTDEPTH'] = this.formatNumberValue(maxEmbedmentDepth, UnitType.mm);
        activities['UtilizationMax'] = this.formatUtilizationValue(Math.max(...maxUtilizations));

        return activities;
    }

    private calculateMaxUtilization(utilizations: UtilizationItem[], strengtheningElementDefinition: boolean) {
        if (strengtheningElementDefinition) {
            const strengthenedMemberStrutResistance = utilizations.find(x => x.utilizationType == UtilizationType.StrengthenedMemberStrutResistance)?.actualValue ?? 0;
            const strengthenedMemberPostInstalledReinforcement = utilizations.find(x => x.utilizationType == UtilizationType.StrengthenedMemberPostInstalledReinforcement)?.actualValue ?? 0;
            return Math.max(strengthenedMemberStrutResistance, strengthenedMemberPostInstalledReinforcement);
        } else {
            const existingMemberConcreteResistance = utilizations.find(x => x.utilizationType == UtilizationType.ExistingMemberConcreteResistance)?.actualValue ?? Number.POSITIVE_INFINITY;
            const existingMemberShearReinforcement = utilizations.find(x => x.utilizationType == UtilizationType.ExistingMemberShearReinforcement)?.actualValue ?? Number.POSITIVE_INFINITY;
            return Math.min(existingMemberConcreteResistance, existingMemberShearReinforcement);
        }
    }

    private keepMaxValue(current: number | null, next: number | null): number | null {
        if (current == null) {
            return next;
        } else if (next == null) {
            return current;
        } else {
            return Math.max(current, next);
        }
    }

    /** Track utilization value and return current max utilization value */
    private trackUtilizationValueAndReturnMax(utilization: UtilizationItem, zoneNumber: number,
        activities: TrackingActivities) {
        // only add utilization types that represent percentage values, other values need custom logic
        const utilizationTypeTracknameMap: Partial<Record<UtilizationType, string>> = {
            [UtilizationType.ExistingMemberStrutResistance]: 'UtilizationExStrut',
            [UtilizationType.ExistingMemberConcreteResistance]: 'UtilizationExConcrete',
            [UtilizationType.ExistingMemberShearReinforcement]: 'UtilizationExReinf',
            [UtilizationType.StrengthenedMemberStrutResistance]: 'UtilizationPirStrut',
            [UtilizationType.StrengthenedMemberPostInstalledReinforcement]: 'UtilizationPirReinf',
        };

        const trackingKey = utilizationTypeTracknameMap[utilization.utilizationType];

        if (trackingKey)
            activities[`Zone${zoneNumber}${trackingKey}`] = this.formatUtilizationValue(utilization.actualValue);
    }

    private trackUtilizationNumberValue(utilization: UtilizationItem, zoneNumber: number,
        activities: TrackingActivities) {
        // only add utilization types that represent number values
        const utilizationTypeTracknameMap: Partial<Record<UtilizationType, string>> = {
            [UtilizationType.AdditionalTensileForceFromShear]: 'DeltaFtd'
        };

        const trackingKey = utilizationTypeTracknameMap[utilization.utilizationType];

        if (trackingKey)
            activities[`Zone${zoneNumber}${trackingKey}`] = this.formatNumberValue(utilization.actualValue, UnitType.N);
    }

    private formatUtilizationValue(value: number | null): string | undefined {
        if (value == null)
            return undefined;

        // utilizations are always rounded up, so that if value is 100.1%, design should technically be overutilized and not satisfied
        // and so we should show that as 101% instead of 100%
        return this.numberService.format(Math.ceil(value), 0);
    }
}
