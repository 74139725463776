import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3, Vector3 } from '@babylonjs/core/Maths/math';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CreatePolygon } from '@babylonjs/core/Meshes/Builders/polygonBuilder';
import { FloatArray } from '@babylonjs/core/types';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import isEqual from 'lodash-es/isEqual';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { PunchBaseMaterialModel, VisibilityModel } from '../../punch-gl-model';
import { baseMaterialAlphaIndex } from './punch-helper';
import earcut from 'earcut';

interface PunchBaseMaterialModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
}

interface OpeningInfo {
    length: number;
    width: number;
    originX: number;
    originY: number;
}

export const baseMaterialColor = Color3.FromHexString('#cbcabf');
export const baseMaterialDarkColor = Color3.FromHexString('#a9a89a');
export const baseMaterialDarkColor2 = Color3.FromHexString('#dd88ee');

export class PunchBaseMaterial extends PunchComponent {
    private baseMaterialMaterial!: StandardMaterial;
    private baseMaterialMaterialDark!: StandardMaterial;
    private materialMeshTop: Mesh | null = null;
    private materialMeshBottom: Mesh | null = null;

    private baseMaterialModelUpdateData!: PunchBaseMaterialModelUpdateData;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.baseMaterialModelUpdateData = {} as PunchBaseMaterialModelUpdateData;
        this.initMaterial();
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: this.getVisibilityInfos(),
            baseMaterial: this.model.baseMaterial,
        } as PunchBaseMaterialModelUpdateData;

        if (isEqual(this.baseMaterialModelUpdateData, modelUpdateData)) {
            return;
        }
        this.baseMaterialModelUpdateData = structuredClone(modelUpdateData);

        this.ensureBaseMaterialSides();
        this.ensureBaseMaterialTop();
        this.ensureBaseMaterialBottom();
    }

    private getVisibilityInfos(): VisibilityModel {
        return {
            TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
            ConcreteDimensionsVisible: this.model.visibilityModel.ConcreteDimensionsVisible,
        };
    }

    private initMaterial(): void {
        this.baseMaterialMaterial = this.createMaterial('BaseMaterialMaterial', baseMaterialColor);
        this.baseMaterialMaterialDark = this.createMaterial('BaseMaterialMaterialDark', baseMaterialDarkColor);
    }

    private ensureBaseMaterialSides() {
        const mesh = this.cache.meshCache.create('BaseMaterialSides', () => this.createSidesMesh());
        mesh.setEnabled(true);

        this.setBaseMaterialSidesProperties(mesh);
    }

    private setBaseMaterialSidesProperties(mesh: Mesh) {
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.material = this.baseMaterialMaterial;

        mesh.alphaIndex = baseMaterialAlphaIndex;

        mesh.scaling.x = this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX;
        mesh.scaling.y = this.model.baseMaterial.thickness;
        mesh.scaling.z = this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY;
    }

    private createMaterial(name: string, color: Color3): StandardMaterial {
        const material = this.cache.materialCache.create(name, () => new StandardMaterial(name, this.scene));
        material.diffuseColor = color;
        return material;
    }

    private ensureBaseMaterialTop(): void {
        this.disposeOldTopMesh();

        const shape = this.createBaseShapeForTop();
        const holes = this.createOpeningShapes();
        const thickness = this.model.baseMaterial.thickness;

        this.materialMeshTop = CreatePolygon(
            'MaterialTop',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshTop) {
            this.configureMesh(this.materialMeshTop, thickness);
        }
    }

    private ensureBaseMaterialBottom(): void {
        this.disposeOldBottomMesh();

        const shape = this.createBaseShapeForBottom();
        const holes = this.createOpeningShapes(true);
        const thickness = this.model.baseMaterial.thickness;

        this.materialMeshBottom = CreatePolygon(
            'MaterialBottom',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshBottom) {
            this.configureMesh(this.materialMeshBottom, -thickness);
            this.materialMeshBottom.rotation.x = Math.PI;
        }
    }

    private createBaseShapeForTop(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanNegY)
        ];
    }

    private createBaseShapeForBottom(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanNegY)
        ];
    }

    private createOpeningShapes(isBottom = false): Vector3[][] {
        const openings: OpeningInfo[] = [
            { length: this.model.baseMaterial.punchOpening1Length, width: this.model.baseMaterial.punchOpening1Width, originX: this.model.baseMaterial.punchOpening1OriginX, originY: this.model.baseMaterial.punchOpening1OriginY },
            { length: this.model.baseMaterial.punchOpening2Length, width: this.model.baseMaterial.punchOpening2Width, originX: this.model.baseMaterial.punchOpening2OriginX, originY: this.model.baseMaterial.punchOpening2OriginY },
            { length: this.model.baseMaterial.punchOpening3Length, width: this.model.baseMaterial.punchOpening3Width, originX: this.model.baseMaterial.punchOpening3OriginX, originY: this.model.baseMaterial.punchOpening3OriginY },
        ];

        return openings.map(opening => this.getRectangleShape(opening, isBottom));
    }

    private getRectangleShape({ length, width, originX, originY }: OpeningInfo, isBottom: boolean): Vector3[] {
        const centerX = (-this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX) / 2 + originX;
        const centerZ = isBottom ? (this.model.baseMaterial.spanNegY - this.model.baseMaterial.spanPosY) / 2 - originY : (-this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY) / 2 + originY;
        const halfLength = length / 2;
        const halfWidth = width / 2;

        return [
            new Vector3(centerX - halfLength, 0, centerZ - halfWidth),
            new Vector3(centerX + halfLength, 0, centerZ - halfWidth),
            new Vector3(centerX + halfLength, 0, centerZ + halfWidth),
            new Vector3(centerX - halfLength, 0, centerZ + halfWidth)
        ];
    }

    private configureMesh(mesh: Mesh, thickness: number): void {
        mesh.setEnabled(true);
        mesh.material = this.baseMaterialMaterial;
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.alphaIndex = baseMaterialAlphaIndex;

        const offsetX = (-this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX) / 2;
        const offsetZ = (-this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY) / 2;

        mesh.position = new Vector3(-offsetX, thickness / 2, -offsetZ);
    }

    private createSidesMesh() {
        const positions = [
            -0.5, -0.5, -0.5,
            0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,

            -0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, -0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, -0.5,
            -0.5, 0.5, 0.5,

            -0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, -0.5, 0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, 0.5,
            0.5, 0.5, 0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, -0.5,
            0.5, -0.5, -0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, 0.5, -0.5,
        ];
        const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

        return this.createMesh(positions, indices, 'BaseMaterialSides');
    }

    private createMesh(positions: number[], indices: number[], name: string) {
        const mesh = new Mesh(name, this.scene);
        const normals: FloatArray = [];
        const vertexData = new VertexData();

        VertexData.ComputeNormals(positions, indices, normals);

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.applyToMesh(mesh);

        return mesh;
    }

    private disposeOldBottomMesh(): void {
        if (this.materialMeshBottom) {
            this.materialMeshBottom.dispose();
            this.materialMeshBottom = null;
        }
    }

    private disposeOldTopMesh(): void {
        if (this.materialMeshTop) {
            this.materialMeshTop.dispose();
            this.materialMeshTop = null;
        }
    }

    public override dispose(): void {
        this.disposeOldBottomMesh();
        this.disposeOldTopMesh();
        super.dispose();
    }
}