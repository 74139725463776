import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { AddEditDesignInputsConcreteMaterialComponent } from './components/add-edit-design-inputs-concrete-material/add-edit-design-inputs-concrete-material.component';
import { AddEditDesignInputsInterfaceShearCalculationComponent } from './components/add-edit-design-inputs-interface-shear-calculation/add-edit-design-inputs-interface-shear-calculation.component';
import { AddEditDesignInputsSafetyFactorsComponent } from './components/add-edit-design-inputs-safety-factors/add-edit-design-inputs-safety-factors.component';
import { AddEditDesignInputsSteelMaterialComponent } from './components/add-edit-design-inputs-steel-material/add-edit-design-inputs-steel-material.component';
import { AddEditDesignPunchComponent } from './components/add-edit-design-punch/add-edit-design-punch.component';
import { AddEditDesignStrengthComponent } from './components/add-edit-design-strength/add-edit-design-strength.component';
import { AppSettingsPunchComponent } from './components/app-settings-punch/app-settings-punch.component';
import { AppSettingsStrengthComponent } from './components/app-settings-strength/app-settings-strength.component';
import { DesignSectionComponent } from './components/design-section/design-section.component';
import { DesignVerificationChangesComponent } from './components/design-verification-changes-popup/design-verification-changes.component';
import { DesignVerificationChangesLoopComponent } from './components/design-verification-changes-popup/design-verification-loop-helper/design-verification-loop-helper.component';
import { ExportReportSummaryPunchComponent } from './components/export-report-summary-punch/export-report-summary-punch.component';
import { ExportReportSummaryStrengthComponent } from './components/export-report-summary-strength/export-report-summary-strength.component';
import { ExportReportComponent } from './components/export-report/export-report.component';
import { GlModelComponent } from './components/gl-model/gl-model.component';
import { InfoDialogCoverComponent } from './components/info-dialogs/existing-reinforcement/cover/info-dialog-cover.component';
import { InfoDialogEffectiveHeighComponent } from './components/info-dialogs/existing-reinforcement/effective-height/info-dialog-effective-height.component';
import { InfoDialogCrossSectionalAreaComponent } from './components/info-dialogs/existing-reinforcement/total-cross-sectional-area/info-dialog-as.component';
import { InfoDialogDepthOfRecessComponent } from './components/info-dialogs/installation-conditions/depth-of-recess/info-dialog-depth-of-recess.component';
import { InfoDialogDrillingAidComponent } from './components/info-dialogs/installation-conditions/drilling-aid/info-dialog-drilling-aid.component';
import { InfoDialogReinforcementEffectivenessComponent } from './components/info-dialogs/loads/reinforcement-effectiveness/info-dialog-reinforcement-effectiveness.component';
import { InfoDialogPunchOpeningComponent } from './components/info-dialogs/opening-using/info-dialog-punch-using-opening.component';
import { InfoDialogInstallationDirectionComponent } from './components/info-dialogs/post-installed-element/installation-direction/info-dialog-installation-direction.component';
import { InfoDialogTransverseEccentricityComponent } from './components/info-dialogs/post-installed-element/transverse-eccentricity/info-dialog-transverse-eccentricity.component';
import { LoadsRowPunchComponent } from './components/loads-row/loads-row-punch.component';
import { LoadsRowStrengthComponent } from './components/loads-row/loads-row-strength.component';
import { LoadsPunchComponent } from './components/loads/loads-punch.component';
import { LoadsStrengthComponent } from './components/loads/loads-strength.component';
import { MainComponent } from './components/main/main.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { UiInitComponent } from './components/ui-init/ui-init.component';
import { ZoneUtilizationItemComponent } from './components/utilizations/zone-utilization-item/zone-utilization-item.component';
import { ZoneUtilizationPanelComponent } from './components/utilizations/zone-utilization-panel/zone-utilization-panel.component';
import { CloseOutsideNgbDropdownDirective } from './directives/close-outside-ngb-dropdown.directive';
import { L10nDirective } from './directives/l10n.directive';
import { L10nPipe } from './pipes/l10n.pipe';
import { QueryService } from './services/query.service';

@NgModule({
    declarations: [
        L10nDirective,
        CloseOutsideNgbDropdownDirective,
        L10nPipe,
        UiInitComponent,
        AddEditDesignInputsSafetyFactorsComponent,
        AddEditDesignInputsConcreteMaterialComponent,
        AddEditDesignInputsSteelMaterialComponent,
        AddEditDesignInputsInterfaceShearCalculationComponent,
        AddEditDesignStrengthComponent,
        AddEditDesignPunchComponent,
        AppSettingsStrengthComponent,
        AppSettingsPunchComponent,
        MainComponent,
        DesignSectionComponent,
        LoadsRowStrengthComponent,
        LoadsRowPunchComponent,
        LoadsStrengthComponent,
        LoadsPunchComponent,
        GlModelComponent,
        ExportReportComponent,
        ProductSelectionComponent,
        ZoneUtilizationPanelComponent,
        ZoneUtilizationItemComponent,
        ExportReportSummaryStrengthComponent,
        ExportReportSummaryPunchComponent,
        InfoDialogDrillingAidComponent,
        InfoDialogDepthOfRecessComponent,
        InfoDialogCrossSectionalAreaComponent,
        InfoDialogCoverComponent,
        InfoDialogEffectiveHeighComponent,
        InfoDialogInstallationDirectionComponent,
        InfoDialogTransverseEccentricityComponent,
        InfoDialogReinforcementEffectivenessComponent,
        NotificationsComponent,
        InfoDialogPunchOpeningComponent,
        DesignVerificationChangesComponent,
        DesignVerificationChangesLoopComponent
    ],
    imports: [
        BrowserModule,
        NgbTooltipModule,
        NgbDropdownModule,
        FormsModule,
        VirtualScrollerModule,
        SortablejsModule.forRoot({})
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-sp/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig,
        queryService: QueryService
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('ui-init', UiInitComponent);
        this.registerCustomElement('add-edit-design-strength', AddEditDesignStrengthComponent);
        this.registerCustomElement('add-edit-design-punch', AddEditDesignPunchComponent);
        this.registerCustomElement('app-settings-strength', AppSettingsStrengthComponent);
        this.registerCustomElement('app-settings-punch', AppSettingsPunchComponent);
        this.registerCustomElement('main', MainComponent);
        this.registerCustomElement('export-report', ExportReportComponent);
        this.registerCustomElement('product-selection', ProductSelectionComponent);
        this.registerCustomElement('info-dialog-drilling-aid', InfoDialogDrillingAidComponent);
        this.registerCustomElement('info-dialog-depth-of-recess', InfoDialogDepthOfRecessComponent);
        this.registerCustomElement('info-dialog-as', InfoDialogCrossSectionalAreaComponent);
        this.registerCustomElement('info-dialog-cover', InfoDialogCoverComponent);
        this.registerCustomElement('info-dialog-effective-height', InfoDialogEffectiveHeighComponent);
        this.registerCustomElement('info-dialog-installation-direction', InfoDialogInstallationDirectionComponent);
        this.registerCustomElement('info-dialog-transverse-eccentricity', InfoDialogTransverseEccentricityComponent);
        this.registerCustomElement('info-dialog-reinforcement-effectiveness', InfoDialogReinforcementEffectivenessComponent);
        this.registerCustomElement('info-dialog-punch-using-opening', InfoDialogPunchOpeningComponent);
        this.registerCustomElement('design-verification-changes', DesignVerificationChangesComponent);

        queryService.initQuery();
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<unknown>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`sp-${componentName}`, customElement);
    }
}

