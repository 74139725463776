<pe-modal-header [modalInstance]="modalInstance" [title]="getTitle()"></pe-modal-header>

<pe-modal-body>
    <div>

        <div class="info-dialog-image">
            <img class="image" [src]="effectiveHeightImage" alt="effective-height-image">
        </div>
        <span l10n="SP.ExistingReinforcement.d.InfoPopUp.PartOne"></span>
        <br />
        <span l10n="SP.ExistingReinforcement.d.InfoPopUp.PartTwo"></span>
        <br />
        <br />
        <span l10n="SP.ExistingReinforcement.d.InfoPopUp.PartThree"></span>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="translate('SP.General.OK')">
    </pe-button>
</pe-modal-footer>
