import { Component, ViewEncapsulation } from '@angular/core';
import openingImage from '../../../images/opening-image.png';
import { InfoDialogBaseComponent } from '../info-dialog-base.component';

@Component({
    templateUrl: './info-dialog-punch-using-opening.component.html',
    styleUrls: ['../info-dialog-style.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogPunchOpeningComponent extends InfoDialogBaseComponent {

    public openingImage = openingImage;

    public getTitle(): string {
        return this.translate('SP.General.Warning');
    }
}
