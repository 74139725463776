import { Vector3 } from '@babylonjs/core/Maths/math';
import { PunchModel } from '../../punch-gl-model';

export const baseMaterialAlphaIndex = 2;

export function getPunchSpanOffset(model: PunchModel): Vector3 {
    return new Vector3(
        getPunchSpanOffsetX(model),
        getPunchSpanOffsetY(model),
        getPunchSpanOffsetZ(model)
    );
}
export function getPunchSpanOffsetX(model: PunchModel) {
    const spanX = model.baseMaterial.spanNegX + model.baseMaterial.spanPosX;
    return -spanX / 2 + model.baseMaterial.spanNegX;
}
export function getPunchSpanOffsetY(model: PunchModel) {
    const spanY = model.baseMaterial.spanNegY + model.baseMaterial.spanPosY;
    return -spanY / 2 + model.baseMaterial.spanNegY;
}
export function getPunchSpanOffsetZ(model: PunchModel) {
    return model.baseMaterial.thickness / 2;
}
