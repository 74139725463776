import { Injectable } from '@angular/core';
import { IConfirmChangeProps } from '@profis-engineering/pe-ui-common/components/confirm-change/confirm-change.common';
import { IAddEditDesignComponentFromModuleInput } from '@profis-engineering/pe-ui-common/entities/add-edit-design-component';
import { IApplicationError } from '@profis-engineering/pe-ui-common/entities/application-error';
import { IDesignSectionExportComponentInput } from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { ChangedConvertedProperties } from './design.service';
import { LocalizationService } from './localization.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private baseService!: ModalServiceBase;

    constructor(
        private localizationService: LocalizationService,
    ) { }

    public setBaseService(baseService: ModalServiceBase): void {
        this.baseService = baseService;
    }

    public openSpecificationText(specificationText: string, showPerfectSpec?: boolean) {
        this.baseService.openSpecificationText(specificationText, showPerfectSpec);
    }

    public openWebComponentModal(componentName: string, options?: ModalOptions, inputValues?: object): IModalOpened {
        return this.baseService.openWebComponentModal(componentName, options, inputValues);
    }

    public openConfirmChange(props?: IConfirmChangeProps): IModalOpened {
        return this.baseService.openConfirmChange(props);
    }

    public openAlertWarning(title: string, message: string): IModalOpened {
        return this.baseService.openAlertWarning(title, message);
    }

    public openAlertError(title: string, message: string, applicationError?: IApplicationError): IModalOpened {
        return this.baseService.openAlertError(title, message, applicationError);
    }

    public openDesignSectionExport(props?: IDesignSectionExportComponentInput): void {
        this.baseService.openDesignSectionExport(props);
    }

    public openAlertGLError(): IModalOpened {
        return this.baseService.openAlertGLError();
    }

    public openVirtualTourPopup(selectTab?: (tab: string) => void): void {
        this.baseService.openVirtualTourPopup(selectTab);
    }

    public openAddEditDesignFromModule(props?: IAddEditDesignComponentFromModuleInput): IModalOpened {
        return this.baseService.openAddEditDesignFromModule(props);
    }

    public openSaveAsTemplate(props?: object): void {
        this.baseService.openSaveAsTemplate(props);
    }

    public openGeneralNotes(text: string, copyText: string): void {
        this.baseService.openGeneralNotes(text, copyText);
    }

    public openSupport(applicationError?: IApplicationError, projectDesign?: object): void {
        this.baseService.openSupport(applicationError, projectDesign);
    }

    public openExportReport(props?: object): void {
        this.openWebComponentModal('sp-export-report', { size: 'lg' }, props);
    }

    public async openConfirmDialog(id: string, message: string): Promise<boolean> {
        let result = false;
        return await this.openConfirmChange({
            id: id,
            title: this.localizationService.getString('SP.General.Warning'),
            message: message,
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            cancelButtonText: this.localizationService.getString('SP.General.Cancel'),
            onConfirm: (modal) => {
                modal.close();
                result = true;
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => result);
    }

    public async showConvertWarningDialog(changedProperties: ChangedConvertedProperties[]): Promise<void> {
        if (changedProperties === undefined || changedProperties.length == 0) return;

        this.openWebComponentModal('sp-design-verification-changes', undefined, { changedProperties: changedProperties });
    }

    public async showRequiresConfirmationDialog(warnings: string) {
        if (warnings === '') return;

        this.openConfirmChange({
            id: 'sp-design-convert-dialog-warnings',
            title: this.localizationService.getString('SP.General.Warning'),
            message: warnings,
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            onConfirm: (modal) => modal.close(),
        });
    }

    public async openUnsupportedDesignModal(message: string) {
        this.openConfirmChange({
            id: 'sp-unsupported-design-dialog',
            title: this.localizationService.getString('SP.UnsupportedDesign.Dialog.Title'),
            message: this.localizationService.getString(message),
            confirmButtonText: this.localizationService.getString('SP.General.OK'),
            onConfirm: (modal) => modal.close(),
        });
    }

}
