import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import {
    CoordinateSystem as BaseCoordinateSystem,
    CoordinateSystemSize
} from '@profis-engineering/gl-model/components/coordinate-system';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { PunchPropertyId } from '../../../services/design.service';
import { MeshCache } from '../../cache/mesh-cache';
import { PunchModel, PunchTooltipKey } from '../../punch-gl-model';
import { getPunchSpanOffset } from './punch-helper';

export class PunchCoordinateSystem extends BaseCoordinateSystem<
    PunchModel,
    PunchPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    PunchTooltipKey,
    ''
> {
    protected calculateCoordinateSystemOffset(): Vector3 {
        const spanOffset = getPunchSpanOffset(this.model);
        return new Vector3(spanOffset.x, spanOffset.z, spanOffset.y);
    }

    protected calculateTransformNode(): TransformNode {
        return this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);
    }

    protected calculateTransformNodeZ(): TransformNode {
        return this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);
    }

    protected calculateTransformNodeY(): TransformNode {
        return this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);
    }

    protected override ensureMeshInternal(): boolean {
        const coordinateSystemOffset = this.calculateCoordinateSystemOffset();

        // position
        this.meshX.position.set(coordinateSystemOffset.x, coordinateSystemOffset.y, coordinateSystemOffset.z);
        this.meshY.position.set(coordinateSystemOffset.x, coordinateSystemOffset.y, coordinateSystemOffset.z);
        this.meshZ.position.set(coordinateSystemOffset.x, coordinateSystemOffset.y, coordinateSystemOffset.z);

        // rotation
        this.meshX.parent = this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);
        this.meshY.parent = this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);
        this.meshZ.parent = this.cache.meshCache.getBaseMemberTransformNode2(this.model.baseMaterial.baseMemberId);

        return true;
    }

    protected calculateCoordinateSystemSize(): CoordinateSystemSize {
        const dashLength = 200;
        const spanOffset = getPunchSpanOffset(this.model);

        return {
            back: spanOffset.y + dashLength,
            right: spanOffset.x + dashLength,
            top: spanOffset.z + dashLength,
        };
    }

    protected calculateTranslateOffsetY(): Vector3 {
        return Vector3.Zero();
    }
}
