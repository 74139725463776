import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    RadioButtonItem
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    TextBoxDisplay
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { LoadsComponentHelper } from '../../loads-component-helper';
import { LoadCombination, LoadType } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { getSpriteAsIconStyle, Sprite } from '../../sprites';

export interface LoadCombinationRowData {
    loadCombination: LoadCombination;
    index: number;
}

@Component({
    selector: 'app-loads-row-strength',
    templateUrl: './loads-row-strength.component.html',
    styleUrls: ['./loads-base.scss', './loads-rows-base.scss', './loads-row-strength.component.scss']
})
/* eslint @angular-eslint/component-class-suffix: ["off"] */
// tslint:disable-next-line: component-class-suffix
export class LoadsRowStrengthComponent implements OnInit {

    @Input()
    public load!: LoadCombination;

    @Input()
    public loadIndex!: number;

    @Input()
    public helper!: LoadsComponentHelper;

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onLoadSelected = new EventEmitter<string>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onAddLoad = new EventEmitter<LoadCombination>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onChangeLoad = new EventEmitter<LoadCombinationRowData>();

    @Output()
    // LEGACY - do not exclude for new code
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onDeleteLoad = new EventEmitter<LoadCombination>();

    public dataLoaded = false;
    public selectedLoadItems: RadioButtonItem<string>[] = [{ text: '', value: '0' }];
    public selectedLoadItemIndex = 0;
    public loadTypeToggleItems: ToggleButtonGroupItem<LoadType>[] = [];

    constructor(
        private localization: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) {

    }

    public ngOnInit(): void {
        const loadSprites: [LoadType, Sprite][] = [
            [LoadType.Static, 'sprite-anchor-shock'],
        ];
        const loadToIconMap = new Map<LoadType, IIconStyle>(loadSprites.map(m => [m[0], getSpriteAsIconStyle(m[1])]));
        this.loadTypeToggleItems = this.loadLoadTypeToggleItems(this.loadIndex, loadToIconMap);

        this.dataLoaded = true;
    }

    public get textBoxDisplay() {
        return TextBoxDisplay.Normal;
    }

    public get loadCombinationRowData() {
        return {
            loadCombination: this.load,
            index: this.loadIndex
        };
    }

    public loadLoadTypeToggleItems(loadIndex: number, loadImages: Map<LoadType, IIconStyle>) {
        const loadTypeToggleItems: ToggleButtonGroupItem<LoadType>[] = [];

        const item: ToggleButtonGroupItem<LoadType> = {
            id: '',
            value: LoadType.Static,
            image: loadImages.has(LoadType.Static) ? loadImages.get(LoadType.Static) : {},
            class: 'sprite-anchor-shock',
            tooltip: this.helper.staticTooltip
        };
        this.setLoadTypeToggleItemId(item, loadIndex);
        loadTypeToggleItems.push(item);

        return loadTypeToggleItems;
    }

    public setLoadTypeToggleItemId(item: ToggleButtonGroupItem<LoadType>, loadIndex: number) {
        if (item.value === LoadType.Static) {
            item.id = loadIndex != null
                ? `LC-table-static-loadtype-button-${loadIndex}`
                : 'LC-table-add-new-combination-static-loadtype-button';
        }
    }

    public loadNameChanged(name: string) {
        if (name !== undefined) {
            if (this.load.loadCombinationName != name) {
                this.load.loadCombinationName = name;
                this.onChangeLoad.emit(this.loadCombinationRowData);
            }
        }
    }

    public loadActiveLoadTypeChange(type: LoadType) {
        if (type !== undefined) {
            const loadType = (type as number) as LoadType;
            if (this.load.loadTypeId != loadType) {
                this.load.loadTypeId = loadType;
                this.onChangeLoad.emit(this.loadCombinationRowData);
            }
        }
    }

    public zoneLoadsValueChange(index: number, value: number) {
        if (index != null && value != null) {
            if (this.load.zoneLoads[index].load != value) {
                this.load.zoneLoads[index].load = value;
                this.onChangeLoad.emit(this.loadCombinationRowData);
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}