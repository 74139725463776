import { PunchBaseUpdate, PunchModel } from '../../../web-gl/punch-gl-model';
import { punchPropertyValueChanged } from '../update';

export class PunchBaseMaterialUpdate extends PunchBaseUpdate {
    @punchPropertyValueChanged('baseMemberId')
    protected updateBaseMemberId(baseMemberId: number, model: PunchModel) {
        model.baseMaterial.baseMemberId = baseMemberId;
    }

    @punchPropertyValueChanged('punchLength')
    protected updatePunchLength(punchLength: number, model: PunchModel) {
        model.baseMaterial.punchLength = punchLength;
    }

    @punchPropertyValueChanged('punchWidth')
    protected updatePunchWidth(punchWidth: number, model: PunchModel) {
        model.baseMaterial.punchWidth = punchWidth;
    }

    @punchPropertyValueChanged('punchDiameter')
    protected updatePunchDiameter(punchDiameter: number, model: PunchModel) {
        model.baseMaterial.punchDiameter = punchDiameter;
    }

    @punchPropertyValueChanged('compressionMemberId')
    protected updateCompressionMemberId(compressionMemberId: number, model: PunchModel) {
        model.baseMaterial.compressionMemberId = compressionMemberId;
    }

    @punchPropertyValueChanged('spanNegX')
    protected update(spanNegX: number, model: PunchModel) {
        model.baseMaterial.spanNegX = spanNegX;
    }
    @punchPropertyValueChanged('spanPosX')
    protected updateSpanPosX(spanPosX: number, model: PunchModel) {
        model.baseMaterial.spanPosX = spanPosX;
    }

    @punchPropertyValueChanged('spanNegY')
    protected updateSpanNegY(spanNegY: number, model: PunchModel) {
        model.baseMaterial.spanNegY = spanNegY;
    }
    @punchPropertyValueChanged('spanPosY')
    protected updateSpanPosY(spanPosY: number, model: PunchModel) {
        model.baseMaterial.spanPosY = spanPosY;
    }

    @punchPropertyValueChanged('thickness')
    protected updateThickness(thickness: number, model: PunchModel) {
        model.baseMaterial.thickness = thickness;
    }

    @punchPropertyValueChanged('openingsNumberId')
    public updatePunchOpeningOpeningsNumberId(openingsNumberId: number, model: PunchModel) {
        model.baseMaterial.openingsNumberId = openingsNumberId;
    }

    @punchPropertyValueChanged('punchOpening1Length')
    public updatePunchOpening1Length(openingLength: number, model: PunchModel) {
        model.baseMaterial.punchOpening1Length = openingLength;
    }

    @punchPropertyValueChanged('punchOpening1Width')
    public updatePunchOpening1Width(openingWidth: number, model: PunchModel) {
        model.baseMaterial.punchOpening1Width = openingWidth;
    }

    @punchPropertyValueChanged('punchOpening1OriginX')
    public updatePunchOpening1OriginX(openingOriginX: number, model: PunchModel) {
        model.baseMaterial.punchOpening1OriginX = openingOriginX;
    }

    @punchPropertyValueChanged('punchOpening1OriginY')
    public updatePunchOpening1OriginY(openingOriginY: number, model: PunchModel) {
        model.baseMaterial.punchOpening1OriginY = openingOriginY;
    }
    @punchPropertyValueChanged('punchOpening2Length')
    public updatePunchOpening2Length(openingLength: number, model: PunchModel) {
        model.baseMaterial.punchOpening2Length = openingLength;
    }

    @punchPropertyValueChanged('punchOpening2Width')
    public updatePunchOpening2Width(openingWidth: number, model: PunchModel) {
        model.baseMaterial.punchOpening2Width = openingWidth;
    }

    @punchPropertyValueChanged('punchOpening2OriginX')
    public updatePunchOpening2OriginX(openingOriginX: number, model: PunchModel) {
        model.baseMaterial.punchOpening2OriginX = openingOriginX;
    }

    @punchPropertyValueChanged('punchOpening2OriginY')
    public updatePunchOpening2OriginY(openingOriginY: number, model: PunchModel) {
        model.baseMaterial.punchOpening2OriginY = openingOriginY;
    }

    @punchPropertyValueChanged('punchOpening3Length')
    public updatePunchOpening3Length(openingLength: number, model: PunchModel) {
        model.baseMaterial.punchOpening3Length = openingLength;
    }

    @punchPropertyValueChanged('punchOpening3Width')
    public updatePunchOpening3Width(openingWidth: number, model: PunchModel) {
        model.baseMaterial.punchOpening3Width = openingWidth;
    }

    @punchPropertyValueChanged('punchOpening3OriginX')
    public updatePunchOpening3OriginX(openingOriginX: number, model: PunchModel) {
        model.baseMaterial.punchOpening3OriginX = openingOriginX;
    }

    @punchPropertyValueChanged('punchOpening3OriginY')
    public updatePunchOpening3OriginY(openingOriginY: number, model: PunchModel) {
        model.baseMaterial.punchOpening3OriginY = openingOriginY;
    }
}
