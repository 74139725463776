<div class="section summary" *ngIf="!collapsed" id="export-report-summary-section">
    <div class="section-row">
        <div class="section-column summary-left model-image-wrapper">
            <img class="model-image" id="export-report-summary-section-3d-model-picture" alt="Model image" [src]="modelImage" />
        </div>

        <div class="section-column summary-right">
            <div class="section-row summary-right-top">
                <div class="section-column">
                    <pe-label
                        class="control-row"
                        [id]="'export-report-section-rebartypeanddiameter-label'"
                        [title]="strengthElementType.label"
                        [text]="strengthElementType.value">
                    </pe-label>
                    <pe-label
                        class="control-row"
                        [id]="'export-report-summary-section-basematerial-label'"
                        [title]="'SP.ExportReport.Summary.BaseMaterial' | l10n: localizationService.selectedLanguage"
                        [text]="baseMaterial.value">
                    </pe-label>
                    <pe-label
                        class="control-row"
                        [id]="'export-report-summary-section-approval-label'"
                        [title]="approval.label"
                        [text]="approval.value">
                    </pe-label>
                </div>
                <div class="section-column anchor-image-wrapper">
                    <span class="anchor sprite-responsive" [ngClass]="'pe-ui-sp-sprite pe-ui-sp-' + strengtheningElementImage"
                        id="export-report-summary-section-product-picture"></span>
                </div>
            </div>
            <div class="section-row summary-right-bottom">
                <div class="section-column">
                    <pe-label
                        class="proof control-row"
                        [id]="'export-report-summary-section-proof-label'"
                        [title]="'Agito.Hilti.Profis3.ExportReport.Summary.Proof' | l10n: localizationService.selectedLanguage"
                        [text]="proof">
                    </pe-label>
                </div>
                <div class="section-column">
                    <pe-label
                        class="drill-length control-row"
                        [id]="'export-report-summary-section-drill-length-label'"
                        [title]="'SP.ExportReport.Summary.DrillLength' | l10n: localizationService.selectedLanguage"
                        [text]="drillLength">
                    </pe-label>
                    <pe-label
                        class="rebar-size control-row"
                        [id]="'export-report-summary-section-rebarsize-label'"
                        [title]="strengthElementSize.label"
                        [text]="strengthElementSize.value">
                    </pe-label>
                </div>
            </div>
        </div>
    </div>
</div>
