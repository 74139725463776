import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { ZoneNumber } from '../../../services/data.service';
import { StrengthModel } from '../../strength-gl-model';
import { GetMinEdgeDistanceX, GetMinEdgeDistanceY, GetSpacingX, GetSpacingY, GetZoneLength, GetZoneWidth } from './zones-helper';

export const numberOfSides = 16;

export function CalculateElementPositions(model: StrengthModel, zoneNumber: ZoneNumber, anchorProtuberance: number) : Vector3[] {
    const minEdgeDistanceX = GetMinEdgeDistanceX(model, zoneNumber);
    const minEdgeDistanceY = GetMinEdgeDistanceY(model, zoneNumber);
    const spacingX = GetSpacingX(model, zoneNumber);
    const spacingY = GetSpacingY(model, zoneNumber);
    const zoneLength = GetZoneLength(model, zoneNumber);
    const zoneWidth = GetZoneWidth(model, zoneNumber);

    const zoneStartX = GetZoneStartX(model, zoneNumber);
    const zoneStartY = GetZoneStartY(model, zoneNumber, zoneWidth);

    const availableSpaceX = zoneLength - 2 * minEdgeDistanceX;
    const availableSpaceY = zoneWidth - 2 * minEdgeDistanceY;

    const rowCount = Math.floor(availableSpaceX / spacingX) + 1;
    const columnCount = Math.floor(availableSpaceY / spacingY) + 1;

    const edgeDistanceX = (zoneLength - (rowCount - 1) * spacingX) / 2;
    const edgeDistanceY = (zoneWidth - (columnCount - 1) * spacingY) / 2;

    const positionZ =  - (model.baseMaterial.height - model.postInstalledElement.drillLength) / 2 - anchorProtuberance + model.postInstalledElement.depthOfRecess;

    const positions: Vector3[] = [];

    let offsetY = edgeDistanceY;
    for (let y = 0; y < columnCount; y++) {
        let offsetX = edgeDistanceX;
        for (let x = 0; x < rowCount; x++) {
            positions.push(new Vector3(zoneStartX + offsetX, positionZ, zoneStartY + offsetY));
            offsetX += spacingX;
        }
        offsetY += spacingY;
    }

    return positions;
}

function GetZoneStartY(model: StrengthModel, zoneNumber: number, zoneWidth: number) {
    if (!model.opening.defineOpening)
        return -zoneWidth / 2;
    switch(zoneNumber) {
        case 1:
        case 2:
        case 3:
            return model.opening.openingOriginY + model.opening.openingWidth / 2;
        case 4:
        case 8:
            return model.opening.openingOriginY - model.opening.openingWidth / 2;
        case 5:
        case 6:
        case 7:
            return -model.baseMaterial.width / 2;
        default:
            throw new Error('Invalid zone number.');
    }
}

function GetZoneStartX(model: StrengthModel, zoneNumber: ZoneNumber) {
    if (model.opening.defineOpening) {
        switch(zoneNumber) {
            case 1:
            case 7:
            case 8:
                return -model.baseMaterial.length / 2;
            case 2:
            case 6:
                return model.opening.openingOriginX - model.opening.openingLength / 2;
            case 3:
            case 4:
            case 5:
                return model.opening.openingOriginX + model.opening.openingLength / 2;
            default:
                throw new Error('Invalid zone number.');
        }
    }
    switch(zoneNumber) {
        case 1:
            return - model.baseMaterial.length / 2;
        case 2:
            return - model.baseMaterial.length / 2 + model.zones.zone1Length;
        case 3:
            return - model.baseMaterial.length / 2 + model.zones.zone1Length + model.zones.zone2Length;
        case 4:
            return - model.baseMaterial.length / 2 + model.zones.zone1Length + model.zones.zone2Length + model.zones.zone3Length;
        default:
            throw new Error('Invalid zone number.');
    }
}
