import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import { IScaleAndPosition, ISceneCoordinateSystemConstructor, marginY, SceneCoordinateSystem as BaseSceneCoordinateSystem } from '@profis-engineering/gl-model/components/scene-coordinate-system';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { PunchPropertyId } from '../../../services/design.service';
import { MeshCache } from '../../cache/mesh-cache';
import { PunchModel, PunchTooltipKey } from '../../punch-gl-model';

interface IMeshInfo {
    parentMesh: Mesh;
    xMesh: LinesMesh;
    yMesh: LinesMesh;
    zMesh: LinesMesh;
    size: ISize;
}

interface ISize {
    x: Vector3;
    y: Vector3;
    z: Vector3;
    position: Vector3;
}

export class PunchSceneCoordinateSystem extends BaseSceneCoordinateSystem<
    PunchModel,
    PunchPropertyId,
    EventNotifier,
    MaterialCache,
    MeshCache,
    PunchTooltipKey,
    ''> {

    constructor(ctor: ISceneCoordinateSystemConstructor<PunchModel, PunchPropertyId, EventNotifier, MaterialCache, MeshCache, PunchTooltipKey, ''>) {
        super(ctor);

        const base = (this as unknown as { calculateSize: () => ISize });
        const baseCalculateSize = base.calculateSize;

        // override calculateSize
        base.calculateSize = function () {
            const size = baseCalculateSize.apply(this);

            // scale up because of camera.minZ = 40
            size.position.scaleInPlace(5);

            return size;
        };
    }

    protected get getMarginVertical() {
        return marginY;
    }

    protected get csRotation() {
        return Matrix.Identity();
    }

    protected get csRotationY() {
        return this.csRotation;
    }

    protected get csRotationZ() {
        return this.csRotation;
    }

    public override update(): void {
        super.update();

        this.updatePositionAndScale();
    }

    protected override beforeCameraRender(): void {
        super.beforeCameraRender();

        this.updatePositionAndScale();
    }

    protected override getScaleAndPositionForReport(): IScaleAndPosition {
        return {
            scale: 2.5,
            position: new Vector3(-2, 0, 6)
        };
    }

    private updatePositionAndScale() {
        const parentMesh = (this as unknown as { meshInfo: IMeshInfo }).meshInfo.parentMesh;

        // scale up because of camera.minZ = 40
        parentMesh.scaling.scaleInPlace(5);
    }
}
