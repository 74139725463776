import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';
import { MeshCache as BaseMeshCache } from '@profis-engineering/gl-model/cache/mesh-cache';
import { BaseMember, ConcreteMember } from '../../services/design.service';

export class MeshCache extends BaseMeshCache<MaterialCache> {

    public getDefaultTransformNode(): TransformNode {
        return this.create('DefaultTransformNode', (name) => {
            return new TransformNode(name, this.sceneCache.default3d);
        });
    }

    public getConcreteMemberTransformNode(concreteMember: ConcreteMember): TransformNode {
        switch (concreteMember) {
            case ConcreteMember.Beam:
                return this.create('BeamConcreteMemberTransformNode', (name) => {
                    return new TransformNode(name, this.sceneCache.default3d);
                });
            case ConcreteMember.Slab:
                return this.create('SlabConcreteMemberTransformNode', (name) => {
                    return new TransformNode(name, this.sceneCache.default3d);
                });
            case ConcreteMember.Wall:
                return this.create('WallConcreteMemberTransformNode', (name) => {
                    const transformNode = new TransformNode(name, this.sceneCache.default3d);
                    transformNode.addRotation(0, Math.PI / 2, 0);

                    return transformNode;
                });
            case ConcreteMember.Column:
                    return this.create('ColumnConcreteMemberTransformNode', (name) => {
                        const transformNode = new TransformNode(name, this.sceneCache.default3d);
                        transformNode.addRotation(0, Math.PI / 2, 0);

                        return transformNode;
                    });
            default:
                throw new Error('Unknown concreteMember');
        }
    }

    public getBaseMemberTransformNode(baseMemberId: BaseMember): TransformNode {
        switch (baseMemberId) {
            case BaseMember.Slab:
                return this.create('SlabBaseMemberTransformNode', (name) => {
                    const transformNode = new TransformNode(name, this.sceneCache.default3d);
                    transformNode.addRotation(Math.PI / 2, 0, 0);
                    return transformNode;
                });
            default:
                throw new Error('Unknown baseMember');
        }
    }
    public getBaseMemberTransformNode2(baseMemberId: BaseMember): TransformNode {
        switch (baseMemberId) {
            case BaseMember.Slab:
                return this.create('SlabBaseMemberTransformNode2', (name) => {
                    const transformNode = new TransformNode(name, this.sceneCache.default3d);
                    return transformNode;
                });
            default:
                throw new Error('Unknown baseMember');
        }
    }

    public getIdentityTransformNode(): TransformNode {
        return this.create('IdentityTransformNode', (name) => {
            return new TransformNode(name, this.sceneCache.default3d);
        });
    }
}
