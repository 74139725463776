<div class="loads-row loads-row-first" *ngIf="dataLoaded">

    <!-- Active -->
    <div class="column-selected">
        <pe-radio-button id="LC-table-select-combination-radio-button-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="selected-container selected-radio-button"
            [items]="selectedLoadItems" [selectedValue]="selectedLoadItemIndex">
        </pe-radio-button>
    </div>

    <!-- Rank -->
    <div class="column-rank">
        <div class="name-container" [ngbTooltip]="load.loadCombinationName">
            <div class="text">{{ loadIndex != null ? loadIndex + 1 : 0 }}</div>
        </div>
    </div>

    <!-- Name -->
    <div class="column-name bordered-column">
        <div id="LC-table-combination-name-textbox-{{loadIndex != null ? loadIndex : 'new-load'}}"
            class="name-container" [ngbTooltip]="load.loadCombinationName != null ? load.loadCombinationName : ''">
            <pe-text-box
                id="{{loadIndex != null ? 'LC-table-combination-name-textbox-' + loadIndex : 'LC-table-add-new-combination-name-textbox'}}"
                class="input name-input clip" [borderTop]="'None'" [borderBottom]="'None'" [borderLeft]="'None'"
                [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay" [value]="load.loadCombinationName"
                (inputBlur)="loadNameChanged($any($event).detail)" [updateOnBlur]="true">
            </pe-text-box>
        </div>
    </div>

    <!-- Load type -->
    <div class="column-type" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
        <pe-toggle-button-group class="type-buttons" [model]="load.loadTypeId"
            (modelChange)="loadActiveLoadTypeChange($any($event).detail)" [items]="loadTypeToggleItems">
        </pe-toggle-button-group>
    </div>

    <!-- Forces and Moments -->
    <div class="column-force column-force-1"
    [ngStyle]="helper.shearForceFlex">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-shear-force-1-' + loadIndex : 'LC-table-add-new-combination-shear-force-1-textbox'}}"
                    class="input shear-force-1-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                    [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                    [value]="load.ved" (valueChange)="loadsValueChange($any($event).detail, 'ved')"
                    [showStepper]="false" [unit]="helper.unitForce" [precision]="helper.getPrecision(helper.unitForce)"
                    [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div *ngIf="helper.isMomentVisible()" class="column-moment column-moment-1">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-moment-1-' + loadIndex : 'LC-table-add-new-combination-moment-1-textbox'}}"
                    class="input moment-1-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                    [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                    [value]="load.medX" (valueChange)="loadsValueChange($any($event).detail, 'medX')"
                    [showStepper]="false" [unit]="helper.unitMoment" [precision]="helper.getPrecision(helper.unitMoment)"
                    [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div *ngIf="helper.isMomentVisible()" class="column-moment column-moment-2">
        <div class="general-number">
            <div class="number-container">
                <pe-numeric-text-box
                    id="{{loadIndex != null ? 'LC-table-moment-2-' + loadIndex : 'LC-table-add-new-combination-moment-2-textbox'}}"
                    class="input moment-2-input number-input clip" [borderTop]="'None'" [borderBottom]="'None'"
                    [borderLeft]="'None'" [borderRight]="'None'" [marginBottom]="'None'" [display]="textBoxDisplay"
                    [value]="load.medY" (valueChange)="loadsValueChange($any($event).detail, 'medY')"
                    [showStepper]="false" [unit]="helper.unitMoment" [precision]="helper.getPrecision(helper.unitMoment)"
                    [appendUnit]="false" [updateOnBlur]="true" [required]="true">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="column-messages"></div>

    <div class="column-delete"></div>

</div>
